import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    [theme.mediaRequests.notMobile]: {
      width: 450,
    },
  }),

  tourItem: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0",

    "& .icon": {
      height: 20,
      position: "relative",

      "& svg": {
        height: 30,
        position: "absolute",
        right: 0,
        top: "50%",
        transform: "translateY(-50%)",
        width: 30,
      },
    },
  },

  tourItemsWrapper: {
    margin: "16px 0",
  },

  photosButton: {
    display: "flex",
    margin: "0 auto 16px",
  },

  sectionTitle: {
    backgroundColor: "rgba(0, 0, 0, .06)",
    padding: 7,
  },
});
