import React, { Fragment } from "react";
import isNil from "lodash/isNil";

import Typography from "@ui-kit/Typography";

interface BoolItemProps {
  label: string;
  state: boolean | null | undefined;
  gutterBottom?: boolean;
  greyEmpty?: boolean;
}

export const BoolItem: React.FC<BoolItemProps> = ({
  label,
  state,
  gutterBottom = true,
  greyEmpty,
  ...props
}) => {
  return (
    <Typography {...props} gutterBottom={gutterBottom}>
      <Typography
        bolded
        variant="inherit"
        color={isNil(state) && greyEmpty ? "textSecondary" : "textPrimary"}
      >
        {label}: {isNil(state) && "---"}
      </Typography>
      {!isNil(state) && (
        <Fragment>
          <Typography
            bolded
            variant="inherit"
            color={state ? "success" : "error"}
          >
            {state ? "Y" : "N"}
          </Typography>
        </Fragment>
      )}
    </Typography>
  );
};
