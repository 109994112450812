import React, { useCallback, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { NewDesignIcons } from "@components/Icons";

import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";

import { Spacer } from "@ui-kit/Spacer";
import { BoolItem } from "@pages/DeepDivePanel/components/BoolItem";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { useCss } from "./styles";
import { Models } from "@services/api";
import { theme } from "@theme";

const VirtualSchoolTour: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [{ facility }] = useDeepDivePanelStore();
  const css = useCss();

  const closeDialog = useCallback(() => {
    history.replace({
      pathname: `/map/${facility.id}`,
      search,
    });
  }, [history, search]);

  const getTourItem = useCallback(
    (label: string, icon?: React.ReactElement, link?: string | null) => {
      return (
        <div css={css.tourItem}>
          <div>
            <Typography variant="inherit" bolded>
              {label}:
            </Typography>{" "}
            {link ? (
              <a
                href={link}
                className="g-link"
                target="_blank"
                rel="noreferrer"
              >
                click to view
              </a>
            ) : (
              <Typography variant="inherit" color="error" bolded>
                N
              </Typography>
            )}
          </div>

          {!!icon && <div className="icon">{icon}</div>}
        </div>
      );
    },
    [],
  );

  return (
    <Dialog
      open
      onClose={closeDialog}
      data-test="virtual-tour-dialog"
      tier={facility.subscriptionTier}
      title="Virtual school tour"
    >
      <div css={css.root}>
        <div
          css={{
            position: "relative",
          }}
        >
          {facility.subscriptionTier === Models.SubscriptionTier.Community && (
            <Fragment>
              <div
                css={{
                  WebkitBackdropFilter: "blur(10px)",
                  backdropFilter: "blur(10px)",
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  bottom: 0,
                  left: 0,
                  position: "absolute",
                  right: 0,
                  top: 0,
                  zIndex: 10,
                }}
              />
              <div
                css={{
                  backgroundColor: theme.palette.text.primary,
                  color: "white",
                  left: "50%",
                  padding: 15,
                  position: "absolute",
                  top: 20,
                  transform: "translateX(-50%)",
                  width: "90%",
                  zIndex: 15,
                }}
              >
                <Typography gap>
                  Virtual school tours are available for Pro and Ivy tier
                  schools.
                </Typography>
                <Typography bolded align="center" paragraph>
                  About school tiers
                </Typography>
                <Typography>
                  School tier reflects parent interest and engagement with the
                  facility, as measured by submitted reviews, likes, shares, as
                  well as school tour and enrollment requests.
                </Typography>
              </div>
            </Fragment>
          )}

          <Typography variant="h5" align="center" css={css.sectionTitle}>
            On-demand tours
          </Typography>

          <Spacer size="small" />

          <div css={css.tourItemsWrapper}>
            {getTourItem(
              "Video tour",
              <NewDesignIcons.Camera />,
              facility.toursInfo?.videoTourUrl,
            )}
            {getTourItem(
              "360-degree tour",
              <NewDesignIcons.Panorama />,
              facility.toursInfo?.vrTourUrl,
            )}
            {getTourItem(
              "Message from faculty",
              <NewDesignIcons.Megaphone />,
              facility.toursInfo?.facultyUrl,
            )}
            {getTourItem(
              "Staff bios & pics",
              <NewDesignIcons.PersonFolder />,
              facility.toursInfo?.staffUrl,
            )}
            {getTourItem(
              "School brochure",
              <NewDesignIcons.Brochure />,
              facility.toursInfo?.brochureFile?.original,
            )}
            {getTourItem(
              "Facility floor plan",
              <NewDesignIcons.Floorplan />,
              facility.toursInfo?.floorplanFile?.original,
            )}
          </div>

          {!facility.bookingActive && (
            <>
              <Spacer size="medium" />

              <Typography variant="h5" align="center" css={css.sectionTitle}>
                Video calls
              </Typography>

              <Spacer size="small" />
              {!facility.toursInfo?.videoCallsAvailable && (
                <BoolItem
                  label="Video calls"
                  state={facility.toursInfo?.videoCallsAvailable}
                />
              )}
              <BoolItem
                label="Zoom calls"
                greyEmpty
                state={
                  facility.toursInfo?.videoCallsAvailable
                    ? !!facility.toursInfo.videoCallsZoom
                    : null
                }
              />
              <BoolItem
                label="Facetime calls"
                greyEmpty
                state={
                  facility.toursInfo?.videoCallsAvailable
                    ? !!facility.toursInfo.videoCallsFacetime
                    : null
                }
              />
              <BoolItem
                label="Whatsapp calls"
                greyEmpty
                state={
                  facility.toursInfo?.videoCallsAvailable
                    ? !!facility.toursInfo.videoCallsWhatsup
                    : null
                }
              />
              <BoolItem
                label="Google duo calls"
                greyEmpty
                state={
                  facility.toursInfo?.videoCallsAvailable
                    ? !!facility.toursInfo.videoCallsGoogleDuo
                    : null
                }
              />

              {facility.toursInfo.videoCallsAvailable && (
                <Typography gutterBottom>
                  <Typography variant="inherit" bolded>
                    Appointment calendar:
                  </Typography>{" "}
                  {facility.toursInfo.videoCallsUrl ? (
                    <a
                      href={facility.toursInfo.videoCallsUrl}
                      className="g-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      click to view
                    </a>
                  ) : (
                    <Typography variant="inherit" color="textSecondary">
                      not available
                    </Typography>
                  )}
                </Typography>
              )}

              {!!facility.toursInfo?.videoCallsDescription && (
                <Typography italic data-test="videoCallsDescription-display">
                  {facility.toursInfo.videoCallsDescription}
                </Typography>
              )}

              <Spacer size="medium" />

              <Typography variant="h5" align="center" css={css.sectionTitle}>
                In-person tours
              </Typography>

              <Spacer size="small" />

              {!facility.toursInfo?.visitsAvailable && (
                <BoolItem
                  label="In-person tours"
                  state={facility.toursInfo?.visitsAvailable}
                />
              )}
              <BoolItem
                label="Daytime tours"
                greyEmpty
                state={
                  facility.toursInfo?.visitsAvailable
                    ? !!facility.toursInfo.visitsAtDaytime
                    : null
                }
              />
              <BoolItem
                label="Evening tours"
                greyEmpty
                state={
                  facility.toursInfo?.visitsAvailable
                    ? !!facility.toursInfo.visitsAtEvening
                    : null
                }
              />
              <BoolItem
                label="Weekend tours"
                greyEmpty
                state={
                  facility.toursInfo?.visitsAvailable
                    ? !!facility.toursInfo.visitsAtWeekends
                    : null
                }
              />

              {facility.toursInfo.visitsAvailable && (
                <Typography gutterBottom>
                  <Typography variant="inherit" bolded>
                    Appointment calendar:
                  </Typography>{" "}
                  {facility.toursInfo.visitsUrl ? (
                    <a
                      href={facility.toursInfo.visitsUrl}
                      className="g-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      click to view
                    </a>
                  ) : (
                    <Typography variant="inherit" color="textSecondary">
                      not available
                    </Typography>
                  )}
                </Typography>
              )}

              {!!facility.toursInfo?.visitsDescription && (
                <Typography italic>
                  {facility.toursInfo.visitsDescription}
                </Typography>
              )}
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default VirtualSchoolTour;
